/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_header = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
			
	
	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.show = function() {

		if(_fixed_header_shown == 0) {
			$('header').stop().animate({
				'top':0
			},500);

			_fixed_header_shown = 1;
		}

	};

	this.hide = function() {

		if(_fixed_header_shown == 1) {
			
			$('header').stop().animate({
				'top':-150
			},500);

			_fixed_header_shown = 0;
		}

	};

}).apply(specific_header); 