/*
Version 0.140

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2019.

!Changelog

2020
	17-02-2020
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_scroller = {};

(function(){
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************

	this.onready = function() {
			
		/*
		Click
		*/
		$(document).on('click','.js-scroll2',function() {

			var _id = $(this).data('scroll2');

			specific_scroller.scroll2(_id);

		});

		/*
		Scroll directly on load
		*/
		switch(url[1]) {

			case 'informatie':
				specific_scroller.scroll2( 'informatie');
			break;

			case 'uitlegvideo':
				specific_scroller.scroll2( 'uitlegvideo');
			break;

			case 'veelgestelde-vragen':
				specific_scroller.scroll2( 'veelgesteldevragen');
			break;

			case 'contact':
				specific_scroller.scroll2( 'contact');
			break;

		}

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	this.scroll2 = function(_id) {

		var _marge = 80;
		if($(window).width() > 500) {
			_marge = 150;
		}

		$('html,body').stop().animate({
			scrollTop: $('.js-scroll2-' + _id).offset().top - _marge
		}, 500);

	};

}).apply(specific_scroller); 